const request = require('superagent');
const _ = { 'get': require('lodash/get') };

$(document).ready(function() {

	// Globals
	const url = _.get(window, 'params.apiUrl');
	const propertyId = _.get(window, 'params.propertyId');
	const eId = getParameterByName('e') || getParameterByName('id');
	const sai = getParameterByName('sai');
	const md = getParameterByName('md');
	const mdv = getParameterByName('mdv');
	const ntg = (getParameterByName('ntg') == 1);
	const ssm = (getParameterByName('ssm') == 1);
	const nsb = (getParameterByName('nsb') == 1);
	const utm_source = getParameterByName('utm_source');
	const utm_medium = getParameterByName('utm_medium');
	const utm_campaign = getParameterByName('utm_campaign');

	//
	// Events
	//

	// Cache
	$(document).on('cache', function(event) {
		const eo = getCache();
		if (eo) {
			$('.email-cache-js').val(_.get(eo, 'email'));
			$('.email-cache-js').text(_.get(eo, 'email'));
			$('.preferences-option-js').toArray().forEach((e) => {
				const opt = _.get(eo, 'options', []).find((o) => { return o.key === $(e).data('preferences-option-key'); });
				e.checked = _.get(opt, 'enabled', true);
			});
		}

		const subscribeModal = $('.subscribe-modal-js');
		if (ssm && subscribeModal.length > 0 && !_.get(eo, 'subscribed', false)) {
			$('.subscribe-modal-js').modal('show');
		}

		if (!eo && eId && eId.trim().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			$('.email-cache-js').val(eId.trim());
			$('.email-cache-js').text(eId.trim());
		}
	});

	// Button state logic
	$.fn.action = function(action) {
		if (action === 'loading' && this.data('loading-text')) {
			this.data('original-text', this.html()).html(this.data('loading-text')).prop('disabled', true);
		}
		if (action === 'reset' && this.data('original-text')) {
			this.html(this.data('original-text')).prop('disabled', false);
		}
	};

	// Show/hide results scroll element
	$(window).scroll(function() {
		var targetDiv = document.getElementsByClassName('learn-content-js')[0];
		var elementToHide = document.getElementsByClassName('results-scroll-button-js')[0];
		if (targetDiv && elementToHide) {
			var targetPosition = targetDiv.getBoundingClientRect().bottom;
			var screenHeight = window.innerHeight || document.documentElement.clientHeight;
			if (targetPosition < screenHeight) {
				elementToHide.style.opacity = '0';
			} else {
				elementToHide.style.opacity = '1';
			}
		}
	});

	// Subscribe
	$('.subscribe-js').on('submit', function(event) {
		event.preventDefault();
		const elem = $(this);
		const submit = elem.find('button[type="submit"]');

		// Data
		const propertyId = elem.data('subscribe-property-id');
		const input = elem.find('[data-subscribe-param=email]')[0];
		const email = input.value;

		submit.action('loading');
		subscribe({
			'propertyId': propertyId,
			'email': email,
			'source': {
				'type': utm_medium ?? 'web',
			    'label': utm_source ?? 'form',
			    'action': utm_campaign ?? 'submit'
			}
		}, (error, data) => {
			submit.action('reset');
			if (!error) {
				const e = elem.find('[data-subscribe-success]')[0];
				e.innerHTML = $(e).data('subscribe-success');
				input.value = '';
				$(input).blur();

				// Hide modal
				const subscribeModal = $('.subscribe-modal-js');
				if (subscribeModal.length > 0) {
					subscribeModal.modal('hide');
				}

				// Show modal
				const confirmModal = $('.subscribe-confirm-modal-js');
				if (_.get(data, 'data.isNewSubscriber') && confirmModal.length > 0) {
					confirmModal.modal('show');
				}
			}
			else {
				const e = elem.find('[data-subscribe-success]')[0];
				e.innerHTML = $(e).data('subscribe-error');
			}
		});
	});

	// Preferences
	$('.preferences-js').on('submit', function(event) {
		event.preventDefault();
		const elem = $(this);
		const submit = elem.find('button[type="submit"]');

		// Data
		const propertyId = elem.data('preferences-property-id');
		const input = elem.find('[data-preferences-param=email]')[0];
		const email = input.value;
		const options = elem.find('.preferences-option-js').toArray().map((e) => { return { key: $(e).data('preferences-option-key'), enabled: e.checked }; });

		submit.action('loading');
		subscribe({
			'propertyId': propertyId,
			'email': email,
			'source': {
				'type': utm_medium ?? 'web',
			    'label': utm_source ?? 'form',
			    'action': utm_campaign ?? 'submit'
			},
			'options': options,
			'trigger': false
		}, (error, data) => {
			submit.action('reset');
			if (!error) {
				const e = elem.find('[data-preferences-success]')[0];
				e.innerHTML = $(e).data('preferences-success');

				showBasicModal('fas fa-check-circle', 'You have successfully updated your preferences', null, 'Done', (m) => {
					m.modal('hide');
				});
			}
			else {
				const e = elem.find('[data-preferences-success]')[0];
				e.innerHTML = $(e).data('preferences-error');

				showBasicModal('fas fa-exclamation-circle', 'Something went wrong trying to update your preferences, please try again or contact us', null, 'Done', (m) => {
					m.modal('hide');
				});
			}
		});
	});

	// Unsubscribe
	$('.unsubscribe-js').on('submit', function(event) {
		event.preventDefault();
		const elem = $(this);
		const submit = elem.find('button[type="submit"]');

		// Data
		const propertyId = elem.data('unsubscribe-property-id');
		const input = elem.find('[data-unsubscribe-param=email]')[0];
		const email = input.value;

		submit.action('loading');
		unsubscribe({
			'propertyId': propertyId,
			'email': email,
			'messageData': md,
			'version': mdv
		}, (error, data) => {
			submit.action('reset');
			if (!error) {
				const e = elem.find('[data-unsubscribe-success]')[0];
				e.innerHTML = $(e).data('unsubscribe-success');
				input.value = '';
				$(input).blur();

				showBasicModal('fas fa-check-circle', 'You have successfully unsubscribed', null, 'Done', (m) => {
					window.location.href = `${window.location.origin}`;
				});
			}
			else {
				const e = elem.find('[data-unsubscribe-success]')[0];
				e.innerHTML = $(e).data('unsubscribe-error');

				showBasicModal('fas fa-exclamation-circle', 'Something went wrong trying to unsubscribe, please try again or contact us', null, 'Done', (m) => {
					m.modal('hide');
				});
			}
		});
	});

	// Trivia
	// Polls
	const prefixes = ['trivia', 'poll'];
	prefixes.forEach((prefix) => {
		$(`.${prefix}-js`).on('click', `.${prefix}-option-js`, function(event) {
			const elem = $(this);

			// Toggle option
			elem.parents(`.${prefix}-options-list-js`).find(`.${prefix}-option-js`).removeClass('active');
			elem.addClass('active');
			elem.parents(`.${prefix}-js`).find(`.${prefix}-play-js`).prop('disabled', false);
		});
		$(`.${prefix}-js`).on('click', `.${prefix}-play-js`, function(event) {
			const elem = $(this);
			const parent = elem.parents(`.${prefix}-js`);

			const eo = getCache();
			const propertyId = parent.data('property-id');
			const questionId = parent.data('question-id');
			const selectedIndex = parent.find(`.${prefix}-option-js.active`).data(`${prefix}-option`);

			// Answer
			if (_.get(eo, 'subscribed') || (nsb && _.get(eo, 'email'))) {
				elem.action('loading');
				submitAnswer(propertyId, questionId, {				
					'emailId': _.get(eo, '_id'),
					'selectedIndex': selectedIndex
				}, (error, data2) => {
					if (error || !_.get(data2, 'data.email')) {
						elem.action('reset');
						alert(`Something went wrong trying to submit your answer. Please try again.\n\n(Error: ${error})`);
					}
					else {
						window.location.href = `${window.location.origin}${window.location.pathname}${window.location.pathname.endsWith('/') ? '' : '/'}results/${data2.data.email}`;
					}
				});
			}
			// Show modal
			else {
				parent.find(`.${prefix}-subscribe-modal-js`).modal('show');
			}
		});
		$(`.${prefix}-js`).on('submit', `.${prefix}-subscribe-js`, function(event) {
			event.preventDefault();
			const elem = $(this);
			const parent = elem.parents(`.${prefix}-js`);
			const submit = elem.find('button[type="submit"]');

			// Data
			const propertyId = parent.data('property-id');
			const questionId = parent.data('question-id');
			const selectedIndex = parent.find(`.${prefix}-option-js.active`).data(`${prefix}-option`);
			const email = elem.find('[data-subscribe-param=email]')[0].value;
			
			const eo = getCache();
			const emailChanged = (_.get(eo, 'email') !== email);
			const untg = (ntg && !emailChanged);

			// Submit
			if (questionId && selectedIndex >= 0) {
				submit.action('loading');

				// Subscribe
				subscribe({
					'propertyId': propertyId,
					'email': email,
					'source': {
						'type': utm_medium ?? 'web',
			    		'label': utm_source ?? 'form',
			    		'action': utm_campaign ?? prefix
					},
					'trigger': untg
				}, (error, data) => {
					if (error) {
						submit.action('reset');
						const e = elem.find('[data-subscribe-success]')[0];
						e.innerHTML = $(e).data('subscribe-error');
					}
					// Answer
					else {
						const isNewSubscriber = _.get(data, 'data.isNewSubscriber');
						submitAnswer(propertyId, questionId, {				
							'emailId': data.data._id,
							'selectedIndex': selectedIndex,
							'trigger': (isNewSubscriber && !untg)
						}, (error2, data2) => {
							if (error2 || !_.get(data2, 'data.email')) {
								submit.action('reset');
								alert(`Something went wrong trying to submit your answer. Please try again.\n\n(Error: ${error2})`);
							}
							else {
								const confirmModal = parent.find(`.${prefix}-confirm-modal-js`);
								if (isNewSubscriber && !untg && confirmModal.length > 0) {
									submit.action('reset');
									parent.find(`.${prefix}-subscribe-modal-js`).modal('hide');
									confirmModal.modal('show');
								}
								else {
									window.location.href = `${window.location.origin}${window.location.pathname}${window.location.pathname.endsWith('/') ? '' : '/'}results/${data2.data.email}`;
								}
							}
						});
					}
				});
			}
		});
	});


	// 
	// Methods
	//

	function getParameterByName(name, url) {
	    if (!url) url = window.location.href;
	    name = name.replace(/[\[\]]/g, '\\$&');
	    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
	        results = regex.exec(url);
	    if (!results) return null;
	    if (!results[2]) return '';
	    return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	function cache(id) {

		// Get email
		const eo = getCache();

		// Update
		if (
			(id && id !== _.get(eo, '_id')) ||
			((id || _.get(eo, '_id')) && window.location.pathname === '/preferences')
		) {
			request.get(`${url}/properties/${propertyId}/emails/${id ?? _.get(eo, '_id')}`)
	    		.then((res) => {
	    			window.localStorage.setItem('e', JSON.stringify(_.get(res, 'body.data')));
    				$(document).trigger('cache');
	    		}, (error) => {
	    			console.log(`Error caching: ${error}`);
					$(document).trigger('cache');
	    		});
		}
		else {
			$(document).trigger('cache');
		}
	}

	function getCache() {
		let eo = window.localStorage.getItem('e');
		if (eo) eo = JSON.parse(eo);
		return eo;
	}

	function subscribe(params, cb) {
		request.post(`${url}/emails/subscribe`)
			.send(params)
    		.then((res) => {
    			window.localStorage.setItem('e', JSON.stringify(_.get(res, 'body.data')));
				$(document).trigger('cache');
    			if (cb) cb(null, res.body);
    		}, (error) => {
    			if (cb) cb(error);
    		});
	}

	function unsubscribe(params, cb) {
		request.post(`${url}/emails/unsubscribe`)
			.send(params)
    		.then((res) => {
    			window.localStorage.removeItem('e');
    			if (cb) cb(null);
    		}, (error) => {
    			if (cb) cb(error);
    		});
	}

	function submitAnswer(propertyId, questionId, params, cb) {
		params.mirror = true;
		request.post(`${url}/contents/properties/${propertyId}/questions/${questionId}/answer`)
			.send(params)
    		.then((res) => {
    			if (cb) cb(null, res.body);
    		}, (error) => {
    			if (cb) cb(error);
    		});
	}

	function showBasicModal(icon, title, description, buttonTitle, cb) {
		const m = $('.basic-modal-js');
		if (m[0]) {
			m.find('.basic-modal-icon-js')[0].className = ('basic-modal-icon-js' + (icon ? ' ' + icon : ''));
			m.find('.basic-modal-icon-js')[0].hidden = icon ? false : true;
			m.find('.basic-modal-title-js')[0].innerHTML = title ?? '';
			m.find('.basic-modal-title-js')[0].hidden = title ? false : true;
			m.find('.basic-modal-desc-js')[0].innerHTML = description ?? '';
			m.find('.basic-modal-desc-js')[0].hidden = description ? false : true;
			m.find('.basic-modal-button-js')[0].innerHTML = buttonTitle ?? '';
			m.find('.basic-modal-button-js')[0].hidden = buttonTitle ? false : true;
			if (buttonTitle && cb) {
				m.find('.basic-modal-button-js')[0].onclick = function() { cb(m); };
			}
			else {
				m.find('.basic-modal-button-js')[0].onclick = null;
			}
			m.modal('show');
		}
	}

	//
	// Exec
	//

	// Cache the email
	cache(eId);

	// Select the answer
	if (sai && sai >= 0) {
		const triviaElem = $('.trivia-js').find('.trivia-option-js')[sai];
		if (triviaElem) triviaElem.click();
		const pollElem = $('.poll-js').find('.poll-option-js')[sai];
		if (pollElem) pollElem.click();
	}

})